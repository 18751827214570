import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import "./assets/styles/_index.scss"
import { Col, Row } from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from 'gatsby'
import { PageLinks } from "../../common/site/page-static-links";
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const FormSidebar = (props) => {
    const data = useStaticQuery(graphql`
        query {
            strapiSiteConfig {
              add_contact_details {
                address
                email
                phone
                title
                opening_hours {
                    data {
                        opening_hours
                    }
                }
              }
            }
        }
    `);

    const siteData = data.strapiSiteConfig.add_contact_details;

    return (
        <div className="form-sidebar-contact-wrapper-main">
            <ScrollAnimation animateIn="animate__slideInUp" delay={300} animateOnce offset={10}>
                <ul className="list-unstyled form-sidebar-contact-wrapper-list">
                    <li>
                        <div className="form-sidebar-contact-wrapper">
                            <Row>
                                <Col xl={12} md={3}>
                                    <div className="form-sidebar-contact-title">Office Address</div>
                                </Col>
                                <Col xl={12} md={9}>
                                    {siteData.address && <div className="form-sidebar-contact-text">{siteData.address}</div>}
                                </Col>
                            </Row>
                        </div>
                    </li>

                    <li>
                        <div className="form-sidebar-contact-wrapper">
                            <Row>
                                <Col xl={12} md={3}>
                                    <div className="form-sidebar-contact-title">Contact Details</div>
                                </Col>
                                <Col xl={12} md={9}>
                                    {siteData.phone?.length > 1 &&
                                        <div className="form-sidebar-contact-text d-flex align-items-center">
                                            <a href={`tel:${siteData.phone}`} className="secondary">{siteData.phone}</a>
                                        </div>
                                    }
                                    {siteData.email?.length > 1 &&
                                        <div className="form-sidebar-contact-text">
                                            <Link to={`/${PageLinks.enquiry}/`} className="secondary" state={{ data: { to_email_id: siteData.email } }}>{siteData.email}</Link>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </li>

                    <li>
                        <div className="form-sidebar-contact-wrapper">
                            <Row>
                                <Col xl={12} md={3}>
                                    <div className="form-sidebar-contact-title">Opening Hours</div>
                                </Col>
                                <Col xl={12} md={9}>
                                    {siteData.opening_hours && <div className="form-sidebar-contact-text"><ContentModule Content={siteData.opening_hours?.data?.opening_hours} /></div>}
                                </Col>
                            </Row>
                        </div>
                    </li>
                </ul>
            </ScrollAnimation>
        </div>
    )
}

export default FormSidebar